<template>
  <scrollable-container>
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />

    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <table
        ref="sumTable"
        :class="$style.table"
      >
        <thead :class="$style.header">
          <th
            v-for="column in sumColumns"
            :key="column.name"
            :style="{ width: column.width }"
          >
            {{ column.name }}
          </th>
        </thead>

        <tbody>
          <tr
            v-for="(value, name, index) in sumTableData"
            :key="index + 'sum'"
            :class="$style.item"
          >
            <td>
              {{ name }}
            </td>
            <td
              v-for="(column, index) in sumColumns.slice(1)"
              :key="index + 'sumcolumn'"
            >
              {{ getSumField(value[column.field], column.field) }}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-if="columns.length < daysNumInARow + 2"
        :class="$style.table"
      >
        <thead :class="$style.header">
          <th
            v-for="column in columns"
            :key="column.name"
            :style="{ width: column.width }"
          >
            {{ column.name }}
          </th>
        </thead>

        <tbody>
          <tr
            v-for="(value, name, index) in tableData"
            :key="index"
            :class="$style.item"
          >
            <td
              :style="{ width: columns[0].width }"
              @contextmenu.prevent="showMenu($event, value)"
            >
              {{ name }}
            </td>
            <td
              v-for="(column, index) in columns.slice(1)"
              :key="index"
              :style="{ backgroundColor: getColor(value[column.name]), width: column.width }"
              @contextmenu.prevent="showMenu($event, value, column.name)"
            >
              {{ getField(value[column.name]) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else>
        <div
          v-for="(group, index) in groupedColumns"
          :key="index"
        >
          <table
            v-if="group.header.length > daysNumInARow"
            :class="$style.table"
          >
            <thead :class="$style.header">
              <tr
                v-for="row in 2"
                :key="'header' + row"
              >
                <th
                  v-if="row === 1"
                  ref=""
                  :style="{ width: columns[0].width }"
                  rowspan="2"
                >
                  {{ columns[0].name }}
                </th>
                <th
                  v-for="column in group.header.slice(daysNumInARow * (row - 1), daysNumInARow * row)"
                  :key="column.name"
                  :style="{ width: column.width }"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>

            <tbody
              v-for="(user, name) in group.data"
              :key="name"
            >
              <tr
                v-for="row in 2"
                :key="'row' + row"
                :class="$style.item"
              >
                <td
                  v-if="row === 1"
                  :style="{ width: columns[0].width }"
                  rowspan="2"
                  @contextmenu.prevent="showMenu($event, user)"
                >
                  {{ name }}
                </td>
                <td
                  v-for="(day, index) in user.slice(daysNumInARow * (row - 1), daysNumInARow * row)"
                  :key="name + index"
                  :style="{ backgroundColor: getColor(day) }"
                  @contextmenu.prevent="showMenu($event, user, day.date)"
                >
                  {{ getField(day) }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            v-else
            :class="$style.notFullSizeTable"
          >
            <thead :class="$style.header">
              <tr>
                <th :style="{ width: nameWidth }">
                  {{ columns[0].name }}
                </th>
                <th
                  v-for="column in group.header"
                  :key="column.name"
                  :style="{ width: dayWidth }"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(user, name) in group.data"
                :key="name"
                :class="$style.item"
              >
                <td
                  :style="{ width: nameWidth }"
                  @contextmenu.prevent="showMenu($event, user)"
                >
                  {{ name }}
                </td>
                <td
                  v-for="(day, index) in user"
                  :key="name + index"
                  :style="{ backgroundColor: getColor(day), width: dayWidth }"
                  @contextmenu.prevent="showMenu($event, user, day.date)"
                >
                  {{ getField(day) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </container>
    <context-menu
      v-show="isMenuVisible"
      id="contextmenu"
      :user-id="selectedUser"
      :mouse-x="mouseX"
      :mouse-y="mouseY"
      :date-from="dateFrom"
      :date-to="dateTo"
      :is-worktime-only="isWorktimeOnly"
      :pages="pages"
    />
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import Loader from '@/components/common/blocks/loader.vue';
import { getHeatMap } from '@/api/methods/reports/users/index';
import { formatDateToDayString, convertSecondsToDays } from '@/helpers/format-date';
import ContextMenu from '@/components/common/contextmenu/custom-contextmenu.vue';
import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    Loader,
    ContextMenu,
  },
  data: () => ({
    name: 'Тепловая карта',
    pages: [
      {
        name: 'worktimeDetails',
        text: 'Детализация',
      },
    ],
    selectedUser: null,
    mouseX: null,
    mouseY: null,
    dateFrom: null,
    dateTo: null,
    isMenuVisible: false,
    daysNumInARow: 16,
    groupedColumns: [],
    groupedData: [],
    sumTableData: [],
    tableData: {},
    state: 2,
    nameWidth: '10%',
    dayWidth: '10%',
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    ro: null,
    text: 'Для построения отчета нажмите кнопку',
    columns: [{ name: 'Пользователь', width: '10%' }],
    sumColumns: [{ name: 'Пользователь' },
      { name: 'Всего дней', field: 'days' },
      { name: 'Всего часов', field: 'hours' },
      { name: 'Переработка/недоработка', field: 'overwork' }],
    colors: {
      0: '#E36363',
      1: '#F65151',
      2: '#FF5C5C',
      3: '#FF745C',
      4: '#FF841F',
      5: '#FF8F33',
      6: '#FFA733',
      7: '#FFBC47',
      8: '#FFDD33',
      9: '#FFFF1F',
      10: '#DCE700',
      11: '#D6FF0A',
      12: '#B0FF1F',
      13: '#6EF500',
      14: '#52DF16',
      15: '#2DB42D',
    },
  }),
  computed: {
    dateOption() {
      return this.$store.getters['filters/dateSelect'];
    },
    switcher() {
      return this.$store.getters['filters/switcher'];
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    dateOption(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.sumTable);
  },

  beforeDestroy() {
    this.ro.unobserve(this.$refs.sumTable);
  },
  methods: {
    onResize() {
      this.nameWidth = `${this.$refs.sumTable.offsetWidth * 0.1}px`;
      this.dayWidth = `${(this.$refs.sumTable.offsetWidth * 0.9) / 16}px`;
    },
    showMenu(event, data, date) {
      if (!date) {
        this.dateFrom = this.dateRange.dateFrom;
        this.dateTo = this.dateRange.dateTo;
      } else {
        const targetDate = new Date((dayjs(date, 'D.MM.YYYY')).toString());
        this.dateFrom = targetDate;
        this.dateTo = targetDate;
      }
      this.selectedUser = Object.values(data)[0].id;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      this.isMenuVisible = true;

      window.addEventListener('click', (e) => {
        if (!document.getElementById('contextmenu').contains(e.target)) {
          this.isMenuVisible = false;
          window.removeEventListener('click', e);
        }
      });
    },
    getColor(value) {
      if (value) return this.colors[value.points];
      return '#FFFFFF';
    },
    getSumField(value, field) {
      if (field === 'overwork') return convertSecondsToDays(value);
      if (field === 'hours') return (value / 3600).toFixed(0);

      return value;
    },
    getField(day) {
      if (!day) return 'B';
      if (day.points || day.points === 0) {
        if (this.switcher === 1) return convertSecondsToDays(day.worktime);
        return `${day.start} / ${day.end}`;
      }
      return 'В';
    },
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) this.computersArray.push(node);
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },
    getDaysDifference(date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      const differenceInTime = date2.getTime() - date1.getTime();
      return differenceInTime / (1000 * 3600 * 24);
    },
    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = {};
      let workTimeOnly = 0;
      this.computersArray = [];
      this.columns = [{ name: 'Пользователь', width: '10%' }];
      this.groupedColumns = [];
      this.groupedData = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        if (this.isWorktimeOnly) workTimeOnly = 1;

        this.promise = getHeatMap(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
          workTimeOnly,
        ).then(({ data }) => {
          if (data && data.result) {
            this.tableData = data.result.heatMap;
            this.sumTableData = data.result.sum;

            const daysNumber = this.getDaysDifference(this.dateRange.dateFrom, this.dateRange.dateTo);

            let date = new Date(this.dateRange.dateFrom);
            for (let i = 0; i < daysNumber; i++) {
              this.columns.push({ name: formatDateToDayString(date), width: `${90 / this.daysNumInARow}%` });
              date = new Date(date.setDate(date.getDate() + 1));
            }

            if (this.columns.length > this.daysNumInARow + 1) {
              const blocksNumber = Math.ceil(Math.ceil((this.columns.length - 1) / this.daysNumInARow) / 2);
              for (let i = 0; i < blocksNumber; i++) {
                this.groupedColumns[i] = {};
                this.groupedColumns[i].header = this.columns.slice(
                  this.daysNumInARow * 2 * i + 1, this.daysNumInARow * 2 * (i + 1) + 1,
                );
              }

              for (let i = 0; i < blocksNumber; i++) {
                this.groupedColumns[i].data = {};
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(this.tableData)) {
                  this.groupedColumns[i].data[key] = [];
                  for (let j = 0; j < this.groupedColumns[i].header.length; j++) {
                    this.groupedColumns[i].data[key].push(value[this.groupedColumns[i].header[j].name]);
                  }
                }
              }
            }
            this.state = this.reportEnum.tableReady;

            const exportArray = [];

            this.$store.commit('exportData/setHeatMap', { name: this.name, data: exportArray });
          } else this.state = this.reportEnum.requestAwaiting;

          return data;
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.notFullSizeTable {
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #F5F5F5;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;

  th {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: pointer;
  }
}

.item {
  height: 29px;
  cursor: pointer;
  border-bottom: 0.2px solid #eee;

  &:hover {
    background: $light-gray;
  }

  td {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    font-size: 14px;
    letter-spacing: 0px;
    text-align: left;
    padding: 7px 4px 7px 10px;
  }
}
</style>
